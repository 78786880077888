import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import {configureStore} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import userReducer from './userReducer';

const persistConfig = {
    key: 'root',
    storage,
    transforms: [
      encryptTransform({
        secretKey: process.env.REACT_APP_STORE_SECRET_KEY,
        onError: function (error) {
          console.log("Transform-Error :",error)
        },
      }),
    ],
}


const persistedReducer = persistReducer(persistConfig, userReducer)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
  })

export const persistor = persistStore(store)
