
import LogoSm from "../assets/img/logo.png";
import Logo from "../assets/img/mcd.rsm.logo.svg";
import { Link, Outlet } from "react-router-dom";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import clsx from "clsx"
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import Button from "./button/Button";
import { useAuthProvider } from "../config/authProvider";

const Navbar = ({children}) => {
  const sidebarActive = useSelector(state => state.sidebarActive)
  
  const dispatch = useDispatch()
  const {handleLogout} = useAuthProvider()

  const toggle_sidebar = (e) => {
    dispatch({type:'toggle_sidebar'});
  }
  return (
    <div className="h-100">
      <div className="container-fluid header-content">
        <div className="row header shadow-sm" style={{borderBottom:"1px solid #DA291C"}}>
          <div 
          className={clsx({
            "col-sm-2": true,
            "col-md-2":true,
            "pl-0": true,
            "text-center": true,
            "header-logo": true,
            "shrink-sidebar": !sidebarActive
          })}
          >
            <div className="bg-theme mr-3 pt-3 pb-2 mb-0">
              <h3 className="logo">
                <Link to="/" className="logo w-100 h-100">
                  <img
                    src={sidebarActive?Logo:LogoSm}
                    alt="McDonalds Logo"
                    className="logo w-100 h-100"
                    style={{ maxHeight: sidebarActive?"44px":"28px", maxWidth: sidebarActive?"138px":"32px" }}
                    width="32"
                    height="28"
                  />
                </Link>
              </h3>
            </div>
          </div>

          <div
          className={
            clsx({
              "col-sm-10": sidebarActive,
              "col-md-10": sidebarActive,
              "col-lg-10":sidebarActive,
              "col-10":sidebarActive,
              "header-menu": true,
              "pt-2": true,
              "pb-0": true,
              "expand-content": !sidebarActive,
              "pl-0": sidebarActive
            })
          }
          >
            {/* <div className="row mt-1"> */}

              <div className="d-flex justify-content-between mt-1">
                <div className="">
                  <span className="menu-icon" onClick={toggle_sidebar}>
                    <span  
                    // className={clsx([
                    //   "sidebar-toggle-btn",
                    //   {"slide-in": sidebarActive}]
                    // )} 
                    className={clsx({
                      "sidebar-toggle-btn": true,
                      "slide-in": sidebarActive
                    })}
                    ></span>
                  </span>
                </div>
                {/* <div className="mt-1">
                  <div className=" portal_name text-center">
                    RSM Portal
                  </div>
                </div> */}
                {/* <div className="form-inline my-2 my-lg-0"> */}
                    <div className="form-inline my-2 my-lg-0">
                      {/* <div className="mr-2 mr-xs-1 d-flex align-items-center">
                        <img
                          src={Img}
                          alt="Adam"
                          className="rounded-circle h-100 w-100"
                          style={{ maxHeight: "38px", maxWidth: "38px" }}
                          width="38px"
                          height="38px"
                        />
                      </div> */}
                      <div className="mr-6">
                        <Button className="logout" size="wide" onClick={handleLogout}>
                          Logout
                        </Button>
                      </div>
                    </div>
                  {/* </div> */}
              </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      <div className="container-fluid d-flex content-section pl-0 pr-0 side-scrollable-content">
          <Sidebar />
          <div  id="scrollable-content" className="content-section pb-4">
              {children?children:<Outlet />}
          </div>
      </div>
    </div>
  );
};

export default Navbar;
