import { useCallback, useEffect, useRef } from  'react';
import { useIsAuthenticated, useMsal} from "@azure/msal-react"
import { loginRequest } from "./authConfig";
import { persistor } from '../store/store';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode'
import { REFRESH_TOKEN } from '../store/constant';


export const useAuthProvider = () => {
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const dispatch = useDispatch()
    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }

    const handleLogout = useCallback(() => {
        persistor.purge();
        localStorage.setItem("log-out", "loggedOut")
        instance.logoutRedirect({
        postLogoutRedirectUri: "/logout",
        });
    },[instance]);
    const groups = accounts[0]?.idTokenClaims?.groups || []
    const isAdmin = groups.filter(val=>val.includes("RSMAdmin")).length>0?true:false;
    const isSuperAdmin = groups.filter((val)=> val.includes("RTPaaS-RSM-AMS-SCCM Admins")).length>0?true:false

    const handleDispatch = useCallback(async () => {
        try{
          if (isAuthenticated) {
            const accessTokenRequest = {
              ...loginRequest,
              account: accounts[0],
              refreshTokenExpirationOffsetSeconds: 14400 // 2 hours * 60 minutes * 60 seconds = 7200 seconds
            };
            let accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
            if(accessTokenResponse){
              const accessToken = accessTokenResponse.accessToken;
              const decodedToken = jwt_decode(accessToken)
              
              dispatch({ type: 'login', payload:{userdata: accounts,tokenData:decodedToken, token: accessToken, isLogged: isAuthenticated,isAdmin,isSuperAdmin }})
              return "Access-Token Acquired"
            }
          }
        }catch(e){
          dispatch({type:"login", payload:{isLogged:false, userdata:accounts}})
          console.log(e);
          handleLogout()
        return 'Access-Token Expired'
        }
      },[accounts,dispatch,isAdmin,isAuthenticated,instance,isSuperAdmin,handleLogout])

    return {
        handleLogin,
        handleLogout,
        handleDispatch,
    }

}

const  REFRESH_THRESHOLD  =  300; // 5 minutes in seconds
const  TOKEN_CHECK_INTERVAL  =  60000 * 1; // 1 minute in milliseconds


export  const  useBackendTokenCheckExpirationTime  = () => {
    const  interval  =  useRef(null);
    const { instance, accounts } =  useMsal();
    const dispatch = useDispatch();
    const token =  useSelector(state=>state.token);
    
    const {handleLogout}  = useAuthProvider()
    
    const  acquireTokenWithRefreshToken  =  useCallback(async () => { 
        try {  
            const accessTokenRequest = {
                ...loginRequest,
                account: accounts[0],
                refreshTokenExpirationOffsetSeconds: 14400
              };
            if (accounts.length  &&  instance) {
                const  response  =  await  instance.acquireTokenSilent(accessTokenRequest);
                const  decodeToken  =  jwt_decode(response.accessToken);
                dispatch({type: REFRESH_TOKEN,payload:{accessToken:response.accessToken,tokenData:decodeToken}})
            }else{
                handleLogout()
            }
        } catch (error) {  
            handleLogout()
        }
    },[dispatch,handleLogout,accounts,instance]);

    useEffect(() => {
        const  checkTokenExpiry  = () => {
            if (token) {
                const  decodeToken  =  jwt_decode(token); 
                const  currentTime  =  Math.floor(Date.now() /  1000); // Current time in seconds
                const  timeUntilExpiry  =  decodeToken.exp  -  currentTime; 
                console.log("TokenExpiresIn",timeUntilExpiry)
                if (timeUntilExpiry  <=  REFRESH_THRESHOLD) {     // Token is about to expire or has expired, refresh it
                    acquireTokenWithRefreshToken();
                }
            }
        };
        interval.current  =  setInterval(checkTokenExpiry, TOKEN_CHECK_INTERVAL);
        checkTokenExpiry(); // Check token expiry immediately after mounting     
        return () =>  clearInterval(interval.current);
    }, [acquireTokenWithRefreshToken,token]);
        return  null; // You might not need to return anything from this hook
};