import { LOGIN,ADD_VALUE_TO_SEARCH_FILTER,REMOVE_FILTER,COUNTRIES_MARKERS,CLEAR_FILTER, LOGOUT, TOGGLE_SIDEBAR, REFRESH_TOKEN, MARKET_DATA, LOCAL_TIMEZONE } from "./constant";
const initialState = {
  token:'',
  user:[],
  search:[],
  countries_markers:[],
  isLoggedIn:false,
  role:null,
  sidebarActive: true,
  marketData:[],
  localTimeZone: ''
};

const userReducer = (state = initialState,action)=>{  
  switch(action.type){
    case LOGIN : 
        return {
            ...state,
            user:action.payload.userdata[0],
            token:action.payload.token,
            isLoggedIn:action.payload.isLogged,
            isAdmin:action.payload.isAdmin,
            isSuperAdmin : action.payload.isSuperAdmin,
            role:action.payload.role,
            tokenData:action.payload.tokenData,
        }
    case ADD_VALUE_TO_SEARCH_FILTER :
        return {
            ...state,
            search:action.addvalue
        }
    case REMOVE_FILTER :
        return {
                ...state,
                search:action.updatedsearchstate
            }
    case COUNTRIES_MARKERS:
        return {
            ...state,
            countries_markers:action.addcountries_markers,
        }
    case CLEAR_FILTER:
        return{
            ...state,
            search:[]   
        }  
    case LOGOUT:
        return{
            ...state,
            user:'',
            token:'',
            isLogged: false,
            isAdmin:false,
            isSuperAdmin:false,
            tokenData:""
        }
    case TOGGLE_SIDEBAR:
        return{
            ...state,
            sidebarActive: !state.sidebarActive,
        }
    case REFRESH_TOKEN:
        return {
            ...state,
            token:action.payload.accessToken,
            tokenData: action.payload.tokenData
        }
    case MARKET_DATA:
        return {
            ...state,
            marketData:action.payload
        }
    case LOCAL_TIMEZONE:
        return {
            ...state,
            localTimeZone: action.payload
        }
    default:
            return state
    }
}

export default userReducer;